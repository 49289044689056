import React from "react"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import Container from "./container"

const Machines = ({ machines }) => (
  <Wrapper>
    <Container>
      <Accordion preExpanded={[machines[0].id]}>
        {machines.map((machine, index) => (
          <AccordionItem key={machine.id} uuid={machine.id}>
            <StyledAccordionItemHeading>
              <AccordionItemButton>
                <Number>{`0` + (index + 1)}</Number>
                <div>{machine.title}</div>
              </AccordionItemButton>
            </StyledAccordionItemHeading>
            <StyledAccordionItemPanel
              dangerouslySetInnerHTML={{ __html: machine.text }}
            />
          </AccordionItem>
        ))}
      </Accordion>
    </Container>
  </Wrapper>
)
const Wrapper = styled.div`
    padding: ${DesignTokens.spacing[5]};
    @media screen and (min-width: 769px) {
      padding: 6rem 0;
    }
`

const StyledAccordionItemHeading = styled(AccordionItemHeading)`
  font-size: ${DesignTokens.fontSizes[5]};
  font-weight: 900;
  color: ${DesignTokens.colors.primary[500]};
  border: 1px solid ${DesignTokens.colors.black};
  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`

const StyledAccordionItemPanel = styled(AccordionItemPanel)`
    padding: 2rem;
  border-left: 1px solid ${DesignTokens.colors.black};
  border-right: 1px solid ${DesignTokens.colors.black};
  border-bottom: 6px solid ${DesignTokens.colors.primary[500]};
  ul {
      margin-bottom: 0;
  }
  li {
      padding: 0 0 .5rem 0;
      line-height: 1.3;
      border-bottom: 1px solid ${DesignTokens.colors.tertiary[100]};
      &:last-child {
          border-bottom: none;
          margin-bottom: 0;
      }
  }
`

const Number = styled.div`
  padding: ${DesignTokens.spacing[4]} ${DesignTokens.spacing[5]};
`


export default Machines
